export const commonType = {
  SPIN: {
    SHOW: "SPIN_SHOW",
    HIDE: "SPIN_HIDE",
  },
  SET_FORMAT_DATE_TIME: "SET_FORMAT_DATE_TIME",
  
  SET_MERCHANT_CODE: "SET_MERCHANT_CODE",
  SET_STORE_CODE: "SET_STORE_CODE",
};
