import { DateTimeEnum } from "../../constants/enums";
import { commonType } from "./common.type";

const initialState = {
  loadSpin: 0,
  formatDatetime: DateTimeEnum.DD_MM_YYYY_HH_MM_SS_Z
};

export function commonReducer(state = initialState, action: any) {
  let _loadSpin = 0;
  switch (action.type) {
    case commonType.SPIN.SHOW:
      _loadSpin = state.loadSpin + 1;
      return {
        ...state,
        loadSpin: _loadSpin,
      };
    case commonType.SPIN.HIDE:
      _loadSpin = state.loadSpin - 1;
      _loadSpin = _loadSpin > 0 ? _loadSpin : 0;
      return {
        ...state,
        loadSpin: _loadSpin,
      };
    case commonType.SET_FORMAT_DATE_TIME:
      return {
        ...state,
        formatDatetime: action.payload,
      };
    case commonType.SET_MERCHANT_CODE:
      return {
        ...state,
        merchantCode: action.payload,
      };
    case commonType.SET_STORE_CODE:
      return {
        ...state,
        storeCode: action.payload,
      };
    default:
      return state;
  }
}
