import { commonType } from "./common.type";

export const commonActions = {
  startSpin,
  stopSpin,
  setFormatDatetime,
  setMerchantCode,
  setStoreCode
};

function startSpin() {
  return (dispatch: any) => {
    dispatch({
      type: commonType.SPIN.SHOW,
    });
  };
}

function stopSpin() {
  return (dispatch: any) => {
    dispatch({
      type: commonType.SPIN.HIDE,
    });
  };
}

function setFormatDatetime(value: any) {
  return (dispatch: any) => {
    dispatch({
      type: commonType.SET_FORMAT_DATE_TIME,
      payload: value
    });
  };
}

function setMerchantCode(code: string) {
  return (dispatch: any) => {
    dispatch({
      type: commonType.SET_MERCHANT_CODE,
      payload: code
    });
  };
}

function setStoreCode(code: string) {
  return (dispatch: any) => {
    dispatch({
      type: commonType.SET_STORE_CODE,
      payload: code
    });
  };
}

